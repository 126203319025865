<template>
	<div class="sld_my_coupon">
		<MemberTitle :memberTitle="L['我的直播']"></MemberTitle>
		<el-button type="danger" @click="startLive">开始直播</el-button>

		<el-table class="liveHistorylist" :data="historylist">
			<el-table-column prop="liveId" label="直播id" />
			<el-table-column prop="liveName" label="直播名称" />
			<el-table-column prop="createTime" label="创建时间" />
			<el-table-column prop="liveStateValue" label="直播状态" />
			<el-table-column prop="playbackTime" label="直播时长" />
			<el-table-column prop="viewingNum" label="观看人数" />
			<el-table-column label="操作" width="120">
				<template #default="scope">
					<!-- 直播中 -->
					<span v-show="scope.row.liveState==1">
						<a href="javascript:;">进入直播</a>
					</span>
					<span v-show="scope.row.liveState!=1">进入直播</span>
				</template>
			</el-table-column>
		</el-table>

		<div class="liveRightSubTit">
			直播间名称
		</div>
		<div class="liveDescItem">
			<el-input v-model="liveTit" placeholder="请输入" />
		</div>

		<div class="liveRightSubTit">
			直播间封面
		</div>
		<div class="liveDescItem">
			<el-upload :http-request="uploadHttpRequest" multiple :show-file-list="true" :limit="1" list-type="picture-card">
				<span style="font-size:40px;">+</span>
			</el-upload>
		</div>

		<div class="liveRightSubTit">
			直播间标签
		</div>
		<div class="liveDescItem">
			<el-select v-model="labelValue" style="width:100%;" placeholder="请选择">
				<el-option v-for="item in labelData" :key="item.labelId" :label="item.labelId"
					:value="item.labelName" />
			</el-select>
		</div>
		
		<div class="liveRightSubTit">
			商品列表
		</div>
		<div class="liveDescItem">
			<a href="javascript:;">添加商品</a>
			<a style="margin-left: 20px;" href="javascript:;">更新商品列表</a>
		</div>

		<div class="liveRightSubTit">
			推流地址 注：请注意保护隐私，以防信息泄露
		</div>
		<div class="liveDescItem">
			<el-input v-model="rtmpPushUrl" type="password" placeholder="Please input password" show-password />
			<div class="copy">复制</div>
		</div>
		<div class="liveRightSubTit">
			推流码 注：请注意保护隐私，以防信息泄露
		</div>
		<div class="liveDescItem">
			<el-input v-model="rtmpPushUrl" type="password" placeholder="Please input password" show-password />
			<div class="copy">复制</div>
		</div>

		<div class="liveStartBtn">
			<div class="confirm" style="display: none;">
				确定
			</div>
			<div class="confirm" style="">
				编辑
			</div>
			<div class="startLive" style=""><img
					src="https://942sports.oss-cn-hongkong.aliyuncs.com/advertise/banner1663664198000.jpg" alt="">
				开始直播
			</div>
		</div>

	</div>
</template>

<script>
	import {
		useRoute
	} from 'vue-router'
	import {
		ref,
		getCurrentInstance,
		onMounted
	} from 'vue'
	import {
		useStore
	} from 'vuex'
	import {
		ElMessage
	} from 'element-plus'
	import MemberTitle from '../../../components/MemberTitle'

	export default {
		name: 'anchorCenter',
		components: {
			MemberTitle
		},
		setup() {
			const route = useRoute()
			const {
				proxy
			} = getCurrentInstance()
			const store = useStore()
			const L = proxy.$getCurLanguage()
			const memberInfo = ref(store.state.memberInfo)
			const liveTit = ref('11')
			const rtmpPushUrl = ref('推流码')

			const historylist = ref([])
			const labelData = ref([])
			const labelValue = ref()

			// 历史直播
			const getLabeHistorylist = () => {
				proxy
					.$get('v3/video/front/video/live/playbackList', {
						authorId: memberInfo.value.memberId,
					})
					.then(res => {
						if (res.state == 200) {
							historylist.value = res.data.list
						}
					})
			}
			// 判断是否正在直播
			const getIsMember = () => {
				proxy
					.$post('v3/video/front/video/live/isMember', {
						memberName: memberInfo.value.memberName,
						bindid: memberInfo.value.memberId,
					})
					.then(res => {
						if (res.state == 200) {
							console.log(res)
						}
						ElMessage.error(res.msg)
					})
			}

			// 直播间标签
			const getLabelList = () => {
				proxy
					.$get('v3/video/front/video/live/labelList')
					.then(res => {
						if (res.state == 200) {
							labelData.value = res.data
						}
					})
			}

			const startLive = () => {
				// 判断是否正在直播
				getIsMember()
			}

			const livecover = ref({})
			const uploadHttpRequest = (option) => {
				proxy.$post(
						'v3/oss/front/upload', {
							source: 'livecover',
							file: option.file
						},
						'form'
					)
					.then(res => {
						if (res.state == 200) {
							livecover.value = res.data
							console.log(livecover)
						}
					})
			}

			onMounted(() => {
				// 历史直播
				getLabeHistorylist()
				// 直播间标签
				getLabelList()
			})
			return {
				L,
				startLive,
				historylist,
				liveTit,
				rtmpPushUrl,
				labelData,
				labelValue,
				uploadHttpRequest
			}
		}
	}
</script>
<style lang="scss" scoped>
	@import '../../../style/anchorCenter.scss';
</style>
<style lang="scss">
	.sld_my_coupon {
		.el-pager li.active {
			color: var(--color_coupon_main);
		}

		.el-pager li:hover {
			color: var(--color_coupon_main);
		}

		.el-pagination button:not([disabled]):hover {
			color: var(--color_coupon_main);
		}

		.el-input__inner:focus {
			border-color: var(--color_coupon_main);
		}
	}
</style>
